<template>
  <div>
    <div v-if="isInitialStatusIsAvaliable && !willCheckIn && !willChangeStatus">
      <v-card-text>
        <div class="d-flex flex-column">
          <span class="mx-auto mb-2">
            Esse quarto já se encontra com status <span class="font-weight-black">disponível</span>.
          </span>
          <span class="mx-auto mb-2">
            O que deseja fazer?
          </span>
          <div class="d-flex mx-auto mb-2 flex-column">
            <v-btn class="ma-2" x-large :loading="loading" @click="onClickToWillCheckIn()" color="primary">Realizar Checkin</v-btn>
            <div class="flex-grow-1 d-flex">
              <v-divider class="my-auto"></v-divider>
              <span class="my-auto mx-2">Ou</span>
              <v-divider class="my-auto"></v-divider>
            </div>
            <v-btn class="ma-2" x-large :loading="loading" @click="onClickToWillChangeStatus()" color="primary">Mudar Status</v-btn>
          </div>
        </div>
      </v-card-text>
    </div>
    <div v-if="willChangeStatus">
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
            <v-btn x-large :loading="loading" @click="save()" color="primary">Salvar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select v-model="status" large outlined label="Status" :items="items"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="status == 'manutencao' || status == 'stand-by'">
          <v-col cols="6">
            <v-text-field v-model="start_at" :min="date_now" :max="expected_end_at" hide-details outlined type="date" label="Data de Inicio"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="expected_end_at" :min="start_at" hide-details outlined type="date" label="Previsão para término"></v-text-field>
          </v-col>
          <v-col>
            <v-textarea v-model="description" rows="6" outlined label="Descrição"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <dialog-available @close="onClose()" :room="room" @updategrid="updateGrid()"  v-if="willCheckIn"/>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import DialogAvailable from '../_components/DialogAvailable.vue';

export default {
  components: {
    DialogAvailable,
  },
  props: {
    room: {},
  },
  data: () => ({
    loading: false,
    isInitialStatusIsAvaliable: false,
    willChangeStatus: false,
    willCheckIn: false,
    status: null,
    description: '',
    start_at: null,
    expected_end_at: null,
    items: [
      { value: 'disponivel', text: 'Disponível' },
      { value: 'manutencao', text: 'Manutenção' },
      { value: 'stand-by', text: 'Stand-by' },
      { value: 'limpeza', text: 'Limpeza' },
      { value: 'conferencia', text: 'Conferência' },
      { value: 'sujo', text: 'Sujo' },
    ],
  }),
  watch: {
    room() {
      this.initializeForm();
    },
  },
  computed: {
    date_now(){
      return new Date(Date.now());
    }
  },
  created() {
    this.initializeForm();
  },
  methods: {
    ...mapActions('manager_room', ['updateStatusRoom']),
    initializeForm() {
      this.status = this.room.status;
      this.description = '';
      if(this.room.lastMaintenance) {
        this.description = this.room.lastMaintenance.description;
        this.expected_end_at = this.room.lastMaintenance.expected_end_at;
        this.start_at = this.room.lastMaintenance.start_at;
      }else if(this.room.lastInfoStatus) {
        this.description = this.room.lastInfoStatus.description;
        this.expected_end_at = this.room.lastInfoStatus.expected_end_at;
        this.start_at = this.room.lastInfoStatus.start_at;
      }
      this.isInitialStatusIsAvaliable = this.status == 'disponivel';
      this.willChangeStatus = !this.isInitialStatusIsAvaliable;
      this.willCheckIn = false;
    },
    save() {
      this.loading = true

      const data = {
        status: this.status,
        room_id: this.room.id,
        description: this.description,
        start_at: this.start_at,
        expected_end_at: this.expected_end_at
      }
      this.updateStatusRoom(data)
        .then(response => {
          if(response)
          this.$toast.success('Salvo com sucesso')
          this.$emit('close')
          this.$emit('updategrid')
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClickToWillChangeStatus () {
      this.willChangeStatus = true;
      this.willCheckIn = false;
    },
    onClickToWillCheckIn () {
      this.willCheckIn = true;
      this.willChangeStatus = false;
    },
    onClose() {
      this.$emit('close');
    },
    updateGrid() {
      this.$emit('updategrid');
    }
  },
}
</script>
